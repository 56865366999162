import React from 'react';
import Gallery from './components/Gallery';
import Footer from './components/Footer';
import "./App.css"


function App() {
  return (
      <div>
        <header className={"header"}>
          <h1>Portfolio Photos</h1>
            <img src="./Thomas.png" alt="logo"/>
        </header>
        <Gallery />

          <Footer />
      </div>
  );
}

export default App;
